<template>
  <div class="container" v-if="data">
    <div>
      <Menu />
    </div>
    <div class="px-6">
      <div class="text-white font-InterBold mt-6 text-center">
        Choose the perfect plan and let the adventure begin!
      </div>
      <div class="mt-4 relative">
        <div class="grid">
          <div
            v-for="pricing in data.pricing"
            :key="pricing.slug"
            class="p-6 my-2"
            @click="selectPackage(pricing.slug)"
            :class="[
              {
                'border-2 border-nColorYellow rounded-3xl':
                  selectedPackage === pricing.slug,
                'border-2 border-nColorBorder bg-nColorTextInput rounded-3xl':
                  selectedPackage != pricing.slug,
              },
            ]"
          >
            <div class="grid grid-cols-1 justify-items-center gap-2 w-full">
              <p class="text-white font-InterBold text-center w-full text-2xl">
                &#x20b9;{{ pricing.price }}
              </p>
              <div
                class="bg-transparent text-nColorLightGray border border-nColorLightGray rounded-2xl font-InterSemiBold text-sm px-6 py-0.5 capitalize text-center"
              >
                {{ pricing.name }}
              </div>
              <div class="w-full">
                <div
                  v-for="feature in data.features"
                  :key="feature.slug"
                  class="text-nColorLightGray font-InterSemiBold mt-6 flex items-center justify-between"
                >
                  <span class="text-sm">{{ feature.name }}</span>
                  <span>
                    <span v-if="pricing[feature.slug] === 'Yes'">
                      <img
                        src="https://playda.s3.ap-south-1.amazonaws.com/diy/plan_page_green_tick.webp"
                        alt="Playda Pricing Feature Yes"
                        width="20"
                        height="20"
                      />
                    </span>
                    <span v-else-if="pricing[feature.slug] === 'No'">
                      <img
                        src="https://playda.s3.ap-south-1.amazonaws.com/diy/plan_page_red_cross.webp"
                        alt="Playda Pricing Feature No"
                        width="20"
                        height="20"
                      />
                    </span>
                    <span v-else class="text-sm">
                      {{ pricing[feature.slug] }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-4 mb-6">
        <button
          @click.prevent="makePayment()"
          class="bg-nColorYellow rounded-3xl w-full py-1 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          <span> Make payment </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import TokenService from "@/common/token.service";
import Menu from "@/components/Elements/MobileMenu.vue";

export default {
  name: "PricingPage",
  components: { Menu },
  data() {
    return {
      data: null,
      selectedPackage: null,
      isSubmitLoading: false,
    };
  },
  created() {
    ApiService.get(apiResource.getPricingDetails).then((data) => {
      this.data = data.data.data;
      this.selectedPackage = this.data.pricing[0].slug;
    });
  },
  methods: {
    selectPackage(slug) {
      this.selectedPackage = slug;
    },
    signOut() {
      this.$store
        .dispatch("signOut")
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {});
    },
    makePayment() {
      TokenService.saveToken("pricing_plan", this.selectedPackage);
      this.$router.push({
        name: "Payment",
      });
    },
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
  },
  computed: {},
};
</script>

<style scoped lang="postcss">
.container {
  height: 100dvh;
}
</style>
